import './App.css';
import PdfViewer from './component/file-viewer.js';
import samplePdf from './assets/Robert_Kunjan-Icons.pdf';
import logo from './assets/img/LogoFinal.png';
import linkedInimg from './assets/img/LinkedIn.png';
import twitterimg from './assets/img/Twitter.png';
import facebookimg from './assets/img/Facebook.png';
import instagramimg from './assets/img/Instagram.png'


function App() {
return (
<div className="App">
<header className="navbar">
          <a
            className="Home-link"
            href="https://kunjanuprety.com"
            rel="home"
          >
            <img src={logo} className="My-logo" alt="Logo" />
          </a>
          
          <a
            className="Back-link"
            href="https://kunjanuprety.com"
            rel="back"
          >
            Back
          </a>
        </header>

        <h1 className='heading'>Inhuman
		</h1>
        <h1 className='heading'>Golden Ration Project
		</h1>

<div className='container'>
<PdfViewer pdf={samplePdf} />
</div>

<footer className="footer">
        <div className='links'>
          <a
            className="home-link"
            href="https://www.linkedin.com/in/kunjanuprety/"
            rel="LinkIn"
          >
            <img src={linkedInimg} className="Link-Linked" alt="Linked In Link" />
          </a>
          
          <a
            className="home-link"
            href="https://twitter.com/KunjanUprety"
            rel="back"
          >
            <img src={twitterimg} className="Link-Twitter" alt="Twitter Link" />
          </a>

          <a
            className="home-link"
            href="https://www.facebook.com/KunjanUpretyMusic/"
            rel="back"
          >
            <img src={facebookimg} className="Link-Facebook" alt="Facebook Link" />
          </a>

          <a
            className="home-link"
            href="https://www.instagram.com/kunjanuprety/"
            rel="back"
          >
            <img src={instagramimg} className="Link-Insta" alt="Instagram Link" />
          </a>
          </div>
        </footer>

        <div className='copyright'>
            <p>Copyright &copy; Kunjan Uprety</p>
        </div>

</div>
);
}

export default App;